.about {
  background-color: #eed9ce;
  overflow: hidden;
}

.aboutCanvas {
  width: 100vw;
  height: 70vh;
}

.aboutContainer {
  transform: translateY(-120px);
  width: 95vw;
  height: 90vh;
  margin: auto auto -50px auto;
  background-color: #dde2e6;
  box-shadow: 0 0 20px #9ba7b3;
  padding-top: 2vmax;
  border-radius: 10vmax;
  position: relative;
  z-index: 1;
  border: 10px solid #9ba7b3;
}

.aboutContainer > p {
  padding: 50px;
  margin: 30px;
  font-size: 1.5em;
  line-height: 1.7em;
  color: rgb(43, 43, 41);
}

.about-overlayText {
  position: absolute;
  top: 37%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #dde2e6;
  text-align: center;
  z-index: 1;
  text-shadow: 0 0 10px #9ba7b3, 0 0 10px #9ba7b3;
}

.about-overlayText h1 {
  font-size: 5em;
  margin: 0;
}

.aboutContactBtn {
  background-color: #eed9ce;
  padding: 5px;
  width: 300px;
  height: 100px;
  font-size: 2em;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 20px auto;
  border: 5px solid #9ba7b3;
  transition: all 1s;
  box-shadow: 0 0 20px #9ba7b3;
}

.aboutContactBtn > p {
  color: #86b3d1;
}

.aboutContactBtn:hover {
  background-color: unset;
  scale: 1.2;
  color: rgb(79, 66, 66);
  font-weight: 900;
}

@media (max-width: 768px) {
  .about-overlayText h1 {
    font-size: 2em;
  }

  .aboutContainer > p {
    padding: 10px;
    margin: 20px;
    font-size: 1.2em;
  }

  .aboutContactBtn {
    width: 200px;
    height: 80px;
    font-size: 1.2em;
  }
}
@media (max-width: 480px) {
  .about-overlayText h1 {
    font-size: 1.5em;
  }
  .aboutContainer > p {
    padding: 10px;
    margin: 20px;
    font-size: 1.1em;
  }
  .aboutContactBtn {
    width: 150px;
    height: 70px;
    font-size: 1.1em;
  }
}
