body {
  background-color: #eed9ce;
  margin: 0;
}

.appBar {
  background-color: #eed9ce !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  padding: 8px 16px;
  box-shadow: none !important;
  position: relative;
  z-index: 1;
}

.logo-img {
  height: 250px;
  width: auto;
  position: absolute;
  z-index: 2000;
  padding-left: 10px;
}

.navMenu {
  margin-right: 16px;
  z-index: 1;
}

.navLinks {
  display: flex;
  gap: 16px;
}

.navButton {
  color: rgb(19, 16, 16) !important;
}

.userMenu {
  margin-right: 16px;
  z-index: 1;
}

@media (max-width: 600px) {
  .navLinks {
    display: none;
  }

  .navMenu {
    margin: 0px;
    align-items: flex-end;
  }

  .userMenu {
    margin-right: 10px;
  }

  .logo-img {
    height: 150px;
    z-index: 1200;
  }
}
