.home {
  background-color: #eed9ce;
  overflow: hidden;
}

.homeCanvas {
  width: 100vw;
  height: 80vh;
  border-radius: 10vmax;
  opacity: 75%;
}

.overlayText {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #eed9ce;
  text-align: center;
  z-index: 1;
  text-shadow: 0 0 3px #ff0000, 0 0 5px rgb(161, 90, 90);
}

.overlayText h1 {
  font-size: 3em;
  margin: 0;
}

.overlayText p {
  font-size: 1.5em;
}

.homeContactBtn {
  background-color: #eed9ce;
  color: rgb(161, 90, 90);
  padding: 5px;
  width: 200px;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 20px auto;
  border: 2px solid lightcoral;
  transition: all 1s;
}

.homeContactBtn > p {
  color: rgb(161, 90, 90);
}

.homeContactBtn:hover {
  background-color: unset;
  scale: 1.2;
  color: rgb(255, 255, 255);
  font-weight: 900;
}

.homeContainer {
  width: 95vw;
  margin: auto;
  background-color: rgb(161, 90, 90);
  padding-top: 2vmax;
  border-radius: 10vmax;
  position: relative;
  z-index: 1;
}

.homeContainer > h3 {
  text-align: center;
  color: rgba(22, 20, 20, 0.904);
  padding: 2vmax;
}

.homeWorksWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2vmax;
  margin: auto;
}

.testimonialSection {
  text-align: center;
  width: 100vw;
  padding-top: 4vmax;
}

.testimonialWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2vmax;
  margin: auto;
}

.resellerSection {
  display: grid;
  grid-template-columns: 1fr; /* Change to one column */
  gap: 20px;
  align-items: stretch;
  padding: 20px;
}

.resellerContainer {
  padding: 4vmax;
  background-color: rgb(161, 90, 90);
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.281);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Ensure full height for flexbox layout */
  border-radius: 10vmax;
}

.resellerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.resellerWrapper > p {
  font-family: "Ubuntu Mono", monospace;
  text-align: center;
  color: rgba(15, 15, 15, 0.822);
  font-size: 1.5vmax;
  letter-spacing: 1px;
  line-height: 1.6;
  margin: 1.5rem;
}

@media (max-width: 768px) {
  .overlayText h1 {
    font-size: 2em;
  }

  .overlayText p {
    font-size: 1em;
  }

  .homeContactBtn {
    width: 150px;
  }

  .resellerSection {
    grid-template-columns: 1fr;
  }

  .resellerContainer {
    padding: 3vmax;
  }

  .resellerWrapper > p {
    font-size: 1.2vmax;
  }
}

@media (max-width: 480px) {
  .overlayText h1 {
    font-size: 1.5em;
  }

  .overlayText p {
    font-size: 0.875em;
  }

  .homeContactBtn {
    width: 100px;
  }

  .resellerContainer {
    padding: 2vmax;
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
  }

  .resellerWrapper > h3 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  .resellerWrapper > p {
    font-size: 1rem;
    line-height: 1.5;
    padding: 1rem;
  }
}

@media screen and (min-width: 600px) {
  .resellerSection {
    grid-template-columns: 1fr 1fr; /* Two columns for screens wider than 600px */
  }
}
