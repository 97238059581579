/* Reset default margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.footer {
  width: 100%;
  display: grid;
  row-gap: 2rem;
  background-color: #263238;
  padding: 1rem 1.5rem;
  position: relative;
  color: #fff;
}

/* Company info section */
.footer-company-info {
  display: grid;
  row-gap: 16px;
}

.footer-company-info .footer-img {
  display: flex;
  align-items: center;
}

.hf-icon {
  height: 70px;
}

.footer-company-info .footer-img span {
  font-size: 1.75rem;
  color: #fff;
  font-weight: bold;
  padding-left: 10px;
}

.footer-company-info .infos {
  display: grid;
  color: #cccccc;
}
.ownerInfos {
  display: grid;
  font-size: 1.1rem;
}

.footer-company-info .footer-icons {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
}

.footer-company-info .footer-icons svg {
  height: 40px; /* Adjust size as needed */
  width: 40px;
  padding: 0.75rem;
  border-radius: 50%;
  background-color: #42555f;
  color: #cccccc;
  cursor: pointer;
}

/* Footer links section */
.footer-links {
  display: grid;
  row-gap: 20px;
  grid-template-columns: 1fr;
}

.footer-links ul {
  display: grid;
  row-gap: 8px;
  list-style: none;
  padding: 0;
}

.footer-links ul li:first-child {
  font-size: 1.25rem;
  color: #fff;
  font-weight: 500;
}

.footer-links ul li:not(:first-child) {
  color: #cccccc;
  cursor: pointer;
}

.footer-links ul li {
  cursor: pointer;
}

.footer-links a {
  color: #cccccc;
  text-decoration: none;
}

.footer-links a:hover {
  color: #fff;
}
/* Footer form section */
.footer-form {
  display: flex;
  flex-direction: column;
}

.footer-form label {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 500;
}

.footer-form input {
  margin-top: 10px;
  width: 100%;
  border: none;
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
}

/* Responsive styles */
@media (min-width: 648px) {
  .footer {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-links {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1012px) {
  .footer {
    grid-template-columns: repeat(3, 1fr);
  }

  .footer-links {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1440px) {
  .footer {
    padding: 1rem calc((100vw - 1440px) / 2);
  }
}
