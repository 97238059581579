.workCard {
  width: 300px;
  transition: all 0.5s;
  padding: 1vmax;
  margin: 1vmax;
  border-radius: 4vmax;
  display: flex;
  flex-direction: column;
}

.content {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.712);
  width: inherit;
  padding: 1.2vmax;
}

.workCard:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.329);
}

@media screen and (max-width: 600px) {
  .workCard {
    width: 200px;
    padding: 0.5vmax;
  }
  .content > h5 {
    font-size: 100%;
  }
  .content > .desc {
    font-size: 70%;
  }
}
