.testimonialCard {
  width: 300px;
  height: 300px;
  transition: all 0.5s;
  padding: 1vmax 0;
  margin: 0.5vmax;
  border-radius: 1vmax;
  display: inline-flex;
  flex-direction: column;
}

.content {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.712);
  width: inherit;
  padding: 1.2vmax;
}

.testimonialCard:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.329);
}

.tmIcon {
  color: cornflowerblue;
}
.starIcon {
  color: rgb(79, 211, 18);
}

.tmCardFooter {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  align-items: end;
  padding: 4vmax 2vmax 0 0;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .testimonialCard {
    width: auto;
    height: auto;
    margin: auto;
  }
  .content > .comment {
    font-size: 80%;
  }
  .content > .tmCardFooter > p {
    font-size: 70%;
  }
}
